<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="姓名:">
              <a-input
                allow-clear
                v-model:value="where.realName"
                placeholder="请输入姓名"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号:">
              <a-input
                allow-clear
                v-model:value="where.mobile"
                placeholder="请输入手机号"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24"></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item></a-col
          >
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="userId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <!-- <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()"
              >新增 <template #icon> <PlusOutlined /> </template
            ></a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template> -->
        <template #name&moible="{ record }">
          <div>{{ record.name }}</div>

          <div>{{ record.moible }}</div>
        </template>
        <template #totalMoney&money="{ record }">
          <div>{{ record.totalMoney }}元</div>

          <div>{{ record.money }}元</div>
        </template>
        <template #Num="{ record }">
          <div>1级:{{ record.firstNum }}</div>

          <div>2级:{{ record.secondNum }}</div>

          <div>3级:{{ record.thirdNum }}</div>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此分组吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent>
                更多
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;">分销订单</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;">提现明细</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-space>
        </template></ud-pro-table
      ></a-card
    >
    <!-- 分销商用户编辑弹窗 -->
    <dealerUserEdit
      v-model:visible="showEdit"
      :data="current"
      @done="reload"
    ></dealerUserEdit>

    <!-- 分销商用户详情弹窗 -->
    <dealerUserDetail
      v-model:visible="showDetail"
      :data="current"
    ></dealerUserDetail>
  </div>
</template>

<script>
import * as dealerUserApi from '@/api/dealer/user.js'
import dealerUserEdit from './index-edit.vue'
import dealerUserDetail from './index-detail.vue'
import { createVNode } from 'vue'
import {
  // PlusOutlined,
  DownOutlined,
  ExclamationCircleOutlined
  // DeleteOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'order-index',
  components: {
    // PlusOutlined,
    // DeleteOutlined,
    DownOutlined,
    dealerUserEdit,
    dealerUserDetail
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '用户ID',
          dataIndex: 'userId'
        },
        {
          title: '会员信息',
          dataIndex: 'id'
        },
        {
          title: '姓名/手机号',
          slots: {
            customRender: 'name&moible'
          }
        },
        {
          title: '累计佣金/可提现佣金',
          slots: {
            customRender: 'totalMoney&money'
          }
        },
        {
          title: '推荐人',
          dataIndex: 'refereeId',
          sorter: true
        },
        {
          title: '下级用户',
          slots: {
            customRender: 'Num'
          },
          sorter: true
        },
        {
          title: '成为时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '220px',
          align: 'center',
          slots: { customRender: 'action' },
          fixed: 'right'
        }
      ],
      // 表格搜索条件
      where: { corpId: this.getCorpId() },
      // 表格排序条件
      order: { sort: 'createTime', order: 'desc' },
      // 表格选中数据
      selection: [],
      // 是否显示详情弹窗
      showDetail: false,
      showEdit: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  methods: {
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      dealerUserApi
        .deleteById(row.userId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    // /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.userId) }
          dealerUserApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    datasource(option, callback) {
      dealerUserApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      const corpId = this.getCorpId()
      this.where = { corpId }
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    /* 编辑 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    }
  }
}
</script>

<style scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
